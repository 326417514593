import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AddInfo from "pages/add-info";
import Beneficiary from "pages/beneficiary";
import CheckInfo from "pages/check-info";
import Decline from "pages/decline";
import ExtendedFamilyCover from "pages/extended-family-cover";
import ExtendedFamilySelection from "pages/extended-family-selection";
import Home from "pages/home";
import MyCover from "pages/my-cover";
import PaymentMethod from "pages/payment-method";
import Profile from "pages/profile";
import QuoteSummary from "pages/quote-summary";
import RatherCallMe from "pages/rather-call-me";
import SelectCover from "pages/select-cover";
import Signature from "pages/signature";
import TermsCondition from "pages/terms-condition";
// import UploadId from "pages/upload-id";
import SignConfirm from "pages/signature-confirm";
import Thankyou from "pages/thankyou";
import Document from "pages/document";
import EmployeeDetails from "pages/employee-details";
import AutoSaveForm from "pages/autosave-form";
import React from "react";
import WacomSignature from "pages/Wacom-signature";

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/add-info" element={<AddInfo />} />

        <Route path="/beneficiary" element={<Beneficiary />} />

        <Route path="/check-info" element={<CheckInfo />} />

        <Route path="/decline" element={<Decline />} />

        <Route path="/employee-details" element={<EmployeeDetails />} />

        <Route path="/extended-family-cover" element={<ExtendedFamilyCover />} />

        <Route path="/extended-family-selection" element={<ExtendedFamilySelection />} />

        <Route path="/my-cover" element={<MyCover />} />

        <Route path="/payment-method" element={<PaymentMethod />} />

        <Route path="/profile" element={<Profile />} />

        <Route path="/quote-summary" element={<QuoteSummary />} />

        <Route path="/rather-call-me" element={<RatherCallMe />} />

        <Route path="/select-cover" element={<SelectCover />} />

        <Route path="/signature" element={<Signature />} />
        
        <Route path="/wacomSignature" element={<WacomSignature />} />

        <Route path="/terms-condition" element={<TermsCondition />} />

        <Route path="/autosave-form" element={<AutoSaveForm />} />

        <Route path="/signature-confirm" element={<SignConfirm />} />

        <Route exact path="/thankyou" element={<Thankyou />} />

        <Route exact path="/document" element={<Document />} />

        <Route exact path="/" element={<Home />} />

      </Routes>
    </Router>
  );
}

export default App;
