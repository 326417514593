import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Toast,
} from "react-bootstrap";
import Footer from "components/footer";
import BtnCompo from "components/btn-compo";
import { useNavigate } from "react-router-dom";

const boxStyle = {
  width: "100%",
  height: "300px",
  border: "1px solid #d3d3d3",
};

const WacomSignature = () => {
  const chkB64 = useRef(true);
  const chkSigText = useRef("");
  const firstName = useRef("");
  const lastName = useRef("");
  const imageBox = useRef("");
  const txtSignature = useRef("");
  const txtDisplay = useRef("");
  const [isSignCaptured, setIsSignatureCaptured] = useState(false);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(false);
  useEffect(() => {
    window.bodyOnLoad(document.getElementById("txtDisplay"));
  }, []);

  const capture = (e) => {
    if (!window.wgssSignatureSDK.running) {
      setErrorMessage(true);
      return;
    }

    window.capture(
      firstName.current,
      lastName.current,
      imageBox.current,
      txtSignature.current,
      chkSigText.current,
      chkB64.current
    );
    if (txtSignature.current) {
      localStorage.setItem("signature", JSON.stringify(txtSignature.current));
    }
  };

  const clear = (e) => {
    window.clearSignature();
    localStorage.removeItem("signature");
    setIsSignatureCaptured(false);
  };
  const onPrintChange = (e) => {
    console.log(e.target.value);
  };

  const saveSignature = () => {
    const signature = JSON.parse(localStorage.getItem("signature"));
    if (signature) {
      setIsSignatureCaptured(true);
    } else setIsSignatureCaptured(false);
  };
  const redirectToDocument = () => {
    window.close();
    navigate("/document");
  };
  return (
      <Container className="h-100 signaturetoasterwrap">
      <div className="signaturetoaster">
        <Toast
          onClose={() => setErrorMessage(false)}
          show={errorMessage}
          delay={2000}
          autohide
        >
          <Toast.Body>Wacom sdk not running</Toast.Body>
        </Toast>
      </div>
        <div className="main-content">
          <h1 className="page-title mb-5">SIGNATURE PAGE</h1>

          <div className="m-auto px-3 pb-5 d-flex flex-column  align-items-center  justify-content-center">
            <div
              id="imageBox"
              ref={imageBox}
              style={boxStyle}
              className="border border-primary rounded p-1 sign-pad"
              title="Double-click a signature to display its details"
            ></div>
            <ul className="btn-list mt-4 mb-2">
              <li>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="fs-6"
                  title="Checks the signature hash"
                  onClick={(e) => {
                    capture(e);
                  }}
                >
                  Capture
                </Button>
              </li>
              <li>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="fs-6"
                  title="Checks the signature hash"
                  onClick={saveSignature}
                >
                  Save
                </Button>
              </li>
              <li>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="fs-6"
                  title="Clears the signature"
                  onClick={clear}
                >
                  Clear
                </Button>
              </li>
            </ul>
            <div style={{ display: "none" }}>
              <textarea
                className="form-control w-100 mt-3"
                rows="10"
                id="txtDisplay"
                ref={txtDisplay}
                onChange={onPrintChange}
              ></textarea>
            </div>
            <div style={{ display: "none" }}>
              <Row>
                <Form.Group as={Col} xs="6">
                  <Form.Label htmlFor="fname">First Name</Form.Label>
                  <Form.Control
                    id="fname"
                    type="text"
                    placeholder="First Name"
                    ref={firstName}
                    defaultValue={``}
                  />
                </Form.Group>
                <Form.Group as={Col} xs="6">
                  <Form.Label htmlFor="lname">Last Name</Form.Label>
                  <Form.Control
                    id="lname"
                    type="text"
                    placeholder="Last Name"
                    ref={lastName}
                    defaultValue={``}
                  />
                </Form.Group>
              </Row>
            </div>
          </div>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/signature"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>

              {isSignCaptured ? (
                <li>
                  <Button
                    className={`btn "btn-primary"`}
                    onClick={redirectToDocument}
                  >
                    NEXT
                  </Button>
                </li>
              ) : null}
            </ul>
          </Footer>
        </div>
      </Container>
  );
};

export default WacomSignature;
