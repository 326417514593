import React, { useEffect, useRef, useState } from "react";
import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import { Button, Container } from "react-bootstrap";
import SignaturePad from "react-signature-pad-wrapper";

const Signature = () => {
  const [signature, setSignature] = useState("");
  const signaturePadRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("signature"))
      setSignature(JSON.parse(localStorage.getItem("signature")));
  }, []);

  const handleSave = (e) => {
    e.preventDefault();
    setSignature(signaturePadRef.current.toDataURL());
    let sign = {
      signature: signaturePadRef.current.toDataURL(),
    };
    localStorage.setItem("signature", JSON.stringify(sign));
  };

  const handleClear = (e) => {
    e.preventDefault();
    signaturePadRef.current.clear();
  };

  return (
    <Container className="h-100">
      <div className="main-content">
        <h1 className="page-title mb-5">SIGNATURE PAGE</h1>

        <div className="m-auto px-3 pb-5 d-flex flex-column  align-items-center  justify-content-center">
          <div className="border border-primary rounded p-1 sign-pad">
            <SignaturePad ref={signaturePadRef} />
          </div>
          <ul className="btn-list mt-4">
            <li>
              <Button
                onClick={handleClear}
                variant="outline-secondary"
                size="sm"
                className="fs-6"
              >
                Clear
              </Button>
            </li>
            <li>
              <Button
                onClick={handleSave}
                variant="outline-primary"
                size="sm"
                className="fs-6"
              >
                Save
              </Button>
            </li>
            <li>
              <BtnCompo
                buttonAction={"/wacomsignature"}
                buttonText={"Wacom"}
                buttonOutline={true}
              >
              </BtnCompo>
            </li>

          </ul>
        </div>

        <Footer>
          <ul className="btn-list">
            <li className="back-li">
              <BtnCompo
                buttonAction={"/signature-confirm"}
                buttonIcon={"/images/icon-back.png"}
                buttonClass={"btn-back"}
                buttonIconHeight={22}
                buttonIconWidth={27}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={"/rather-call-me"}
                buttonText={"RATHER CALL ME"}
                buttonOutline={true}
                buttonClass={"btn-rather"}
              />
            </li>
            <li>
              {signature && (
                <BtnCompo buttonAction={"/document"} buttonText={"NEXT"} />
              )}
            </li>
          </ul>
        </Footer>
      </div>
    </Container>
  );
};

export default Signature;
